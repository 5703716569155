define("frontend/pods/components/marketing-banner/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MarketingBannerComponent = Ember.Component.extend({
    isInfoboxOpen: false,
    selectedNotification: null,
    currentImageIndex: 0,
    // remember when defining start times and end times: the month is zero-based
    notifications: [{
      startTime: new Date(2024, 10, 14),
      endTime: new Date(2024, 10, 21),
      text: "Join us on our first webinar: Mastering Your Cold Calling Game!",
      id: "marketing-webinar_11_24",
      images: ["/images/marketing/webinar_img1_1124.png", "/images/marketing/webinar_img2_1124.png"]
    }],
    activeNotifications: Ember.computed("notifications", function () {
      var notifications = this.notifications;
      var now = new Date();
      var notificationIDs = (localStorage[this.localStorageKey] || "").split(",");
      return notifications.filter(function (n) {
        return n.startTime <= now && n.endTime >= now && !Array.from(notificationIDs).includes(n.id);
      });
    }),
    localStorageKey: "marketing-banner",
    firstImage: Ember.computed("selectedNotification.images", "currentImageIndex", function () {
      var images = this.get("selectedNotification.images");
      var currentIndex = this.get("currentImageIndex");
      return images && images.length > 0 ? images[currentIndex] : null;
    }),
    actions: {
      dismiss: function dismiss(n) {
        var notificationIDs = (localStorage[this.localStorageKey] || "").split(",");
        notificationIDs.push(n.id);
        localStorage[this.localStorageKey] = notificationIDs.join(",");
        return this.notifyPropertyChange("activeNotifications");
      },
      showNotificationInfobox: function showNotificationInfobox(notification) {
        this.set("selectedNotification", notification);
        this.set("currentImageIndex", 0);
        this.set("isInfoboxOpen", true);
      },
      closeInfobox: function closeInfobox() {
        this.set("isInfoboxOpen", false);
        this.set("selectedNotification", null);
        this.set("currentImageIndex", 0);
      },
      nextImage: function nextImage() {
        var currentIndex = this.get("currentImageIndex");
        var images = this.get("selectedNotification.images");
        var imagesLength = images ? images.length : 0;

        if (currentIndex < imagesLength - 1) {
          this.set("currentImageIndex", currentIndex + 1);
          console.log("Next Image: ", this.get("firstImage"));
        } else {
          console.log("No more images.");
        }
      },
      previousImage: function previousImage() {
        var currentIndex = this.get("currentImageIndex");

        if (currentIndex > 0) {
          this.set("currentImageIndex", currentIndex - 1);
          console.log("Previous Image: ", this.get("firstImage"));
        } else {
          console.log("No previous images.");
        }
      }
    }
  });
  var _default = MarketingBannerComponent;
  _exports.default = _default;
});