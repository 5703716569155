define("frontend/pods/components/platform-notifications/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PlatformNotificationsComponent = Ember.Component.extend({
    // remember when defining start times and end times: the month is zero-based
    notifications: [{
      startTime: new Date(2017, 8, 1),
      endTime: new Date(2017, 9, 2),
      text: 'Due to the conditions resulting from Hurricane Harvey, MonsterConnect is temporarily suspending team dialing for Houston and surrounding areas (area codes 281, 346, 713, and 832).  Team dialing will resume on October 2.  Manually dialing leads in these area codes is still possible.',
      id: 'harvey'
    }, {
      startTime: new Date(2022, 11, 21),
      endTime: new Date(2022, 11, 26),
      text: 'Happy Holidays! MonsterConnect will be closed for the holiday this Monday, December 26th. Team dialing will be unavailable, but manual dialing will remain available.',
      id: 'christmas-2022'
    }, {
      startTime: new Date(2022, 11, 28),
      endTime: new Date(2023, 0, 2),
      text: 'Happy Holidays! MonsterConnect will be closed for the holiday this Monday, January 2nd. Team dialing will be unavailable, but manual dialing will remain available.',
      id: 'new-years-2023'
    }, {
      startTime: new Date(2023, 4, 25),
      endTime: new Date(2023, 4, 30),
      text: 'Happy Holidays! MonsterConnect will be closed for the holiday Monday, May 29th. Team dialing will be unavailable, but manual dialing will remain available.',
      id: 'memorial-day-2023'
    }, {
      startTime: new Date(2023, 5, 30),
      endTime: new Date(2023, 6, 5),
      text: "Happy Holidays! MonsterConnect will be closed for the holiday Monday, July 3rd and Tuesday, July 4th. Team dialing will be unavailable, but manual dialing will remain available.",
      id: "independence-day-2023"
    }, {
      startTime: new Date(2023, 7, 31),
      endTime: new Date(2023, 8, 5),
      text: "Happy Holidays! MonsterConnect will be closed for the holiday Monday, September 4th. Team dialing will be unavailable, but manual dialing will remain available.",
      id: "labor-day-2023"
    }, {
      startTime: new Date(2023, 10, 21),
      endTime: new Date(2023, 10, 25),
      text: 'Happy Thanksgiving! MonsterConnect will be closed for the holiday this Thursday and Friday, November 23rd and 24th.  Team dialing will be unavailable, but manual dialing will remain available.',
      id: 'thanksgiving-2023'
    }, {
      startTime: new Date(2023, 11, 20),
      endTime: new Date(2023, 11, 26),
      text: 'Happy Holidays! MonsterConnect will be closed for the holiday next Monday, December 25th. Team dialing will be unavailable, but manual dialing will remain available.',
      id: 'christmas-2023'
    }, {
      startTime: new Date(2023, 11, 28),
      endTime: new Date(2024, 0, 1),
      text: 'Happy Holidays! MonsterConnect will be closed for the holiday, Monday, January 1st. Team dialing will be unavailable, but manual dialing will remain available.',
      id: 'new-years-2024'
    }, {
      startTime: new Date(2024, 4, 6),
      endTime: new Date(2024, 7, 6),
      text: 'Daily scheduled maintenance will temporarily be occurring to the dashboards from 9am – 11am EST. Updated dashboard and reporting information will be available after 12pm EST',
      id: 'dashboard-maintenance-2024'
    }, {
      startTime: new Date(2024, 4, 26),
      endTime: new Date(2024, 4, 28),
      text: 'Happy Holidays! MonsterConnect will be closed for the holiday Monday, May 27th. Team dialing will be unavailable, but manual dialing will remain available.',
      id: 'memorial-day-2024'
    }, {
      startTime: new Date(2024, 6, 4),
      endTime: new Date(2024, 6, 5),
      text: 'Happy Holidays! MonsterConnect will be closed for the holiday Thursday, July 4th. Team dialing will be unavailable, but manual dialing will remain available.',
      id: 'independence-day-2024'
    }, {
      startTime: new Date(2024, 8, 1),
      endTime: new Date(2024, 8, 3),
      text: 'Happy Holidays! MonsterConnect will be closed for the holiday Monday, September 2nd. Team dialing will be unavailable, but manual dialing will remain available.',
      id: 'labor-day-2024'
    }, {
      startTime: new Date(2024, 10, 25),
      endTime: new Date(2024, 10, 30),
      text: 'Happy Thanksgiving! MonsterConnect will be closed for the holiday this Thursday and Friday, November 28th and 29th.  Team dialing will be unavailable, but manual dialing will remain available.',
      id: 'thanksgiving-2024'
    }],
    activeNotifications: Ember.computed('notifications', function () {
      var notifications = this.notifications;
      var now = new Date();
      var notificationIDs = (localStorage[this.localStorageKey] || '').split(',');
      return notifications.filter(function (n) {
        return n.startTime <= now && n.endTime >= now && !Array.from(notificationIDs).includes(n.id);
      });
    }),
    localStorageKey: 'platform-notifications',
    actions: {
      dismiss: function dismiss(n) {
        var notificationIDs = (localStorage[this.localStorageKey] || '').split(',');
        notificationIDs.push(n.id);
        localStorage[this.localStorageKey] = notificationIDs.join(',');
        return this.notifyPropertyChange('activeNotifications');
      }
    }
  });
  var _default = PlatformNotificationsComponent;
  _exports.default = _default;
});